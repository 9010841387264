import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import {
  ONBOARD,
  CONSENT_DOCS,
  ASSETS,
  DEBT,
  ACCOUNTS,
  DISSOLUTION_STAGE,
  INVESTORS,
  STATES,
  TAXES,
} from "../utils/constants";

export const HOME_PATH = '/overview';
export const ORG_DRAWER_PATH = `/sheet/full`;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}



export const stageColors = {
  [ONBOARD]: '#10B981',
  [CONSENT_DOCS]: '#3B82F6',
  [ASSETS]: '#F59E0B',
  [DEBT]: '#EF4444',
  [ACCOUNTS]: '#06B6D4',
  [DISSOLUTION_STAGE]: '#8B5CF6',
  [INVESTORS]: '#EC4899',
  [STATES]: '#F97316',
  [TAXES]: '#10B981',
};

// Add these color mappings
const tailwindColors = {
  'gray-50': '#f9fafb',
  'gray-100': '#f3f4f6',
  'gray-200': '#e5e7eb',
  'gray-300': '#d1d5db',
  'gray-400': '#9ca3af',
  'gray-500': '#6b7280',
  'gray-600': '#4b5563',
  'gray-700': '#374151',
  'gray-800': '#1f2937',
  'gray-900': '#111827',
  
  'blue-100': '#dbeafe',
  'blue-200': '#bfdbfe',
  'blue-300': '#93c5fd',
  'blue-400': '#60a5fa',
  'blue-500': '#3b82f6',
  'blue-600': '#2563eb',
  'blue-700': '#1d4ed8',
  
  'red-100': '#fee2e2',
  'red-200': '#fecaca', 
  'red-300': '#fca5a5',
  'red-400': '#f87171',
  'red-500': '#ef4444',
  'red-600': '#dc2626',
  'red-700': '#b91c1c',
  'red-800': '#991b1b',

  'green-500': '#22c55e',

  'background-secondary': '#f8fafc',
  'background-tertiary': '#e2e8f0',
} as const;

/**
 * Converts a Tailwind color class to its hex value
 */
export function getTailwindColor(colorClass: string): string {
  // Remove 'bg-', 'text-', etc prefixes
  const color = colorClass.replace(/^(bg|text|border)-/, '');
  
  return tailwindColors[color as keyof typeof tailwindColors] || colorClass;
}


export function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}
